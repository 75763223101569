import '@drf-ui/drf-nav-ssr/dist/index.css';
import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { WithAppConfig, LoadGoogleReCaptchaEnterpriseScript } from '@drf-ui/drf-app-config';

import ElasticHOC from './common/ElasticHOC';
import { CombinedStaticDataProvider } from '../utils/CombinedStaticDataContext';
import { ReloadNavProvider } from '../utils/ReloadNavContext';

const FundingRoutes = lazy(() => import('./funding/fundingRoutes'));
const MemberCenterWrapper = lazy(() => import('./member-center/MemberCenterWrapper'));

const AppRoutes = (props) => {
  const { combinedStaticData, useCommonStaticData } = props;

  {
    /*
     * GoogleReCaptchaProvider's responsibility is to load the necessary reCaptcha script and provide access to reCaptcha to the rest of your application.
     * Usually, your application only needs one provider.
     * You should place it as high as possible in your React tree.
     * It's to make sure you only have one instance of Google Recaptcha per page and it doesn't reload unecessarily when your components re-rendered.
     */
  }
  LoadGoogleReCaptchaEnterpriseScript(
    combinedStaticData?.data?.appConfig?.reCaptchaEnterpriseScriptURL,
    true,
    false,
    true
  );

  return (
    <Suspense fallback={<></>}>
      <CombinedStaticDataProvider
        value={{ useCommonStaticData: useCommonStaticData, combinedStaticData: combinedStaticData?.data }}
      >
        <ReloadNavProvider>
          <Router>
            <Switch>
              <Route
                exact
                path="/funding"
                render={({ location }) => {
                  if (location.pathname === '/funding') return <Redirect to="/funding/" />;
                  else return <FundingRoutes />;
                }}
              />
              <Route
                path="/"
                component={MemberCenterWrapper}
              />
            </Switch>
          </Router>
        </ReloadNavProvider>
      </CombinedStaticDataProvider>
    </Suspense>
  );
};

export default ElasticHOC(WithAppConfig(AppRoutes));
