import React, { createContext, useState, useContext } from 'react';

/**
 * A context for managing reload navigation.
 * @property {number} reloadNav - A timestamp representing the last time the navigation was reloaded.
 * @property {Function} updateReloadNav - A function to update the reload navigation timestamp.
 */

const ReloadNavContext = createContext();

/**
 * A custom hook to access the reload navigation context.
 */
export const useReloadNav = () => useContext(ReloadNavContext);

/**
 * A provider component for managing reload navigation.
 */
export const ReloadNavProvider = ({ children }) => {
  const [reloadNav, setReloadNav] = useState(Date.now());

  const updateReloadNav = () => {
    setReloadNav(Date.now());
  };

  return <ReloadNavContext.Provider value={{ reloadNav, updateReloadNav }}>{children}</ReloadNavContext.Provider>;
};
