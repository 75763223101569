import React, { useEffect } from 'react';
import elasticConfigs from '../../config/elasticConfigs';
import { init as initApm } from '@elastic/apm-rum';

const ElasticHOC = (WrappedComponent) => (props) => {
  useEffect(() => {
    initApm({
      serviceName: elasticConfigs.serviceName,
      serverUrl: elasticConfigs.serverUrl,
      serviceVersion: elasticConfigs.serviceVersion,
      environment: elasticConfigs.environment,
    });
  }, []);
  return <WrappedComponent {...props} />;
};
export default ElasticHOC;
