const getEnvironment = () => {
  switch (document.location.hostname) {
    case 'membercenterocal.drf.com':
      return 'development';

    case 'membercenterdev.drf.com':
      return 'development';

    case 'membercenterstage.drf.com':
      return 'staging';

    case 'membercenter.drf.com':
      return 'production';

    default:
      return 'production';
  }
};

const elasticConfigs = {
  serviceName: 'member-center',
  serverUrl: 'https://4dd20068cb574f8dbbbaca781c766d6b.apm.us-east-1.aws.cloud.es.io:443',
  environment: getEnvironment(),
  serviceVersion: '1.0.0',
};

export default elasticConfigs;
